import { Typography } from "@material-tailwind/react";
import MetaTag from "./MetaTag";

const People = () => {
    <MetaTag
        title="The People"
        description="The people behind the project."
        keywords="people behind the project"
        imgsrc="https://user-images.githubusercontent.com/35755386/213597146-7b062310-df93-488d-9dc6-e84036f26eb4.png"
        url="https://www.havit.space/people"
    />
    
    return (
        <div className="home border-l-2 border-black pl-5">
            <Typography
                as="big"
                variant="h1"
                className="text-5xl mr-4 cursor-pointer py-1.5 font-normal">
                    <b>The Five Horsemen.</b>
            </Typography>

            <br />
            <br />

            <Typography
                as="big"
                variant="h1"
                className="text-3xl mr-4 cursor-pointer py-1.5 font-normal">
                    <b>Wonmo "John" Seong.</b>
                    <br />
                    <p>Chief Executive Officer. The Right Honourable.</p>
                    <p className="font-bold text-xl">Wait, so how do I centre a <code>{"<div>"}</code> ?</p>
                    <p className="text-sm text-gray-500">johnseong@havit.space</p>
                    
                    <br />

                    <b>Sajjad Karbalai.</b>
                    <br />
                    <p>Chief Operating Officer. The Next Gwynne Shotwell.</p>
                    <p className="font-bold text-xl">Meetings are not a waste of time.</p>
                    <p className="text-sm text-gray-500">sajjadkarbalai@havit.space</p>

                    <br />

                    <b>Jamie Stewart.</b>
                    <br />
                    <p>Chief Financial Officer. Master of Coin.</p>
                    <p className="font-bold text-xl">Don't invest in crypto.</p>
                    <p className="text-sm text-gray-500">jamiestewart@havit.space</p>

                    <br />

                    <b>Lyn Jeong.</b>
                    <br />
                    <p>Chief Innovation Officer. Madam President.</p>
                    <p className="font-bold text-xl">What do you call new innovations in knife technology?</p>
                    <p className="text-sm text-gray-500">lynjeong@havit.space</p>

                    <br />

                    <b>Rayan Kaissi.</b>
                    <br />
                    <p>Chief Technology Officer. 10x Developer.</p>
                    <p className="font-bold text-xl">I love C++ pointers.</p>
                    <p className="text-sm text-gray-500">rayankaissi@havit.space</p>
            </Typography>
        </div>
    );
};

export default People;