export default function Footer() {
    return (
        <footer className="bg-gray-100 text-gray-600 body-font ">
            <div className="container text-center px-5 py-8 mx-auto items-center">
                <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-1/2 px-4">
                        <h1 className="title font-medium text-gray-900 tracking-widest text-3xl mb-3">
                            hAshCreAte<br /><span className="text-blue-800 underline underline-offset-4 decoration-1 decoration-blue-800">SINCE 2023</span>
                        </h1>
                    </div>
                </div>

                <div className="my-5 mx-auto gap-1.5 flex flex-row w-fit justify-self-center">
                    <button className="flex mx-auto m-2 font-bold text-white bg-black border-2 border-black py-1 px-2 focus:outline-none hover:bg-transparent hover:text-black rounded text-lg">
                        <a href="/people">Our People.</a>
                    </button>

                    <button className="flex mx-auto m-2 font-bold text-black bg-transparent border-2 border-black py-1 px-2 focus:outline-none hover:bg-black hover:text-white rounded text-lg">
                        <a href="/privacy">Your Privacy.</a>
                    </button>
                </div>

                <span className="bg-black text-white rounded p-1">Proudly based in <b>Ontario, Canada</b>.</span>
            </div>
        </footer>
    );
}