import { Typography } from "@material-tailwind/react";
import MetaTag from "./MetaTag";

export default function Privacy() {
    return (
        <div className="home border-l-2 border-black pl-5">
            <MetaTag
                title="Privacy Policy"
                description="Privacy Policy of HAVIT."
                keywords="privacy policy"
                imgsrc="https://user-images.githubusercontent.com/35755386/213597146-7b062310-df93-488d-9dc6-e84036f26eb4.png"
                url="https://www.havit.space/privacy"
            />
            
            <Typography
                as="big"
                variant="h1"
                className="text-5xl mr-4 cursor-pointer py-1.5 font-normal">
                    <b>Your Privacy.</b>
            </Typography>

            <br />
            <br />

            <Typography
                as="big"
                variant="h1"
                className="text-3xl mr-4 cursor-pointer py-1.5 font-normal normal-case">
                    This page is used to inform the users of our product regarding our policies regarding the collection, use, and disclosure of Personal Information if anyone decided to use our service.{" "}
                    If you choose to use <span className="title">HAVIT.</span> then you agree to the collection and use of information in relation to this policy. 
            </Typography>

            <br />
            <br />

            <Typography
                as="big"
                variant="h1"
                className="text-3xl mr-4 cursor-pointer py-1.5 font-bold normal-case">
                    End-to-end Encryption.
            </Typography>


            <div className="ml-5">
                <Typography
                    as="big"
                    variant="h3"
                    className="text-xl mr-4 cursor-pointer py-1.5 font-normal normal-case">
                        <b>With industry-leading cryptographic algorithms that comply with HIPAA, GDPR, and PCI DS.{" "}</b>
                        
                        <br />
                        <br />
                        
                        Our service uses Virgil E3Kit for end-to-end encryption to ensure the privacy and security of our users' data. The following privacy policy outlines how we handle this sensitive information.
                        <br />
                        <br />
                        <b>1. Data Collection</b>: We collect only the necessary information required for the functioning of our service. This information is encrypted and stored securely.
                        <br />
                        <b>2. End-to-End Encryption</b>: All data transmitted through our service is encrypted using Virgil E3Kit, ensuring that only the sender and the recipient can access the information.
                        <br />
                        <b>3. Access Control</b>: Access to encrypted data is restricted to authorized personnel only and is subject to strict security protocols.
                        <br />
                        <b> 4. Data Retention</b>: Encrypted data is stored for as long as required for the functioning of our service and is deleted when no longer needed.
                        <br />
                        <b>5. Third-Party Access</b>: We do not share or sell any encrypted data to any third-party organizations or individuals.
                        <br />
                        <br />
                        We are committed to protecting the privacy and security of our users' data and have implemented robust measures to ensure the protection of all information processed through our service. We are committed to providing a secure and private service that our users can trust.
                </Typography>
            </div>  
        </div>
    );
}