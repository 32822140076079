import { Typography } from "@material-tailwind/react";
import { useState } from "react";
import MetaTag from "./MetaTag";
import YouTube, { YouTubeProps } from 'react-youtube';

/*
* The Home component is used to render the home page
* The MetaTag component is used to render the meta tags
* The Typography component is used to render the text
* The as prop is used to determine the type of text
* The variant prop is used to determine the size of the text
* The className prop is used to determine the styling of the text
* The b tag is used to make the text bold
* The span tag is used to make the text italic
* The a tag is used to make the text a link
* The href prop is used to determine the link
* The target prop is used to determine the target of the link
* The rel prop is used to determine the relationship of the link
* The button tag is used to make the text a button
* The className prop is used to determine the styling of the button
* The onClick prop is used to determine the action of the button
* The div tag is used to make the text a div
*/

export default function Home() {
    const [playVideo, setPlayVideo] = useState(false);


    const opts: YouTubeProps['opts'] = {
        height: '480',
        width: '720',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
    };

    return (
        <div className="home">
            <MetaTag
                title="HAVIT: Habit Tracking, Reimagined."
                description="HAVIT is a habit tracking app that combines social media, video editing, and a daily habit tracker."
                keywords="HAVIT, habit tracking, habit tracker, social media, video editing, daily habit tracker"
                imgsrc="https://user-images.githubusercontent.com/35755386/213597146-7b062310-df93-488d-9dc6-e84036f26eb4.png"
                url="https://www.havit.space"
            />

            <div className="flex flex-col">
                <Typography
                    as="big"
                    variant="h1"
                    className="text-5xl mr-4 cursor-pointer py-1.5 font-normal">
                    {!playVideo ? <b>I am, Because You are.</b> : <b>Because You are, I am.</b>}
                </Typography>

                <Typography
                    as="big"
                    variant="h1"
                    className="text-3xl mr-4 cursor-pointer py-1.5 font-normal">
                        <span className="bg-purple-600 text-white p-1 rounded">Our app</span> brings an <span className="bg-yellow-600 text-white p-1 rounded">unique set</span> of features that combines <span className="underline underline-offset-4 decoration-purple-600">social media</span>, <span className="underline underline-offset-4 decoration-purple-600">video editing platform</span>, and a <span className="underline underline-offset-4 decoration-purple-600">daily habit tracker</span>.
                </Typography>
            </div>

            <button onClick={() => {
                setPlayVideo(!playVideo);
            }} className="mt-5 mr-2 w-fit font-bold text-white bg-black border-2 border-black py-1 px-2 focus:outline-none hover:bg-transparent hover:text-black rounded text-lg">
                <span>{!playVideo ? "Watch our Demo" : "Close"}</span>
            </button>

            <button className="mt-5 w-fit font-bold text-black bg-transparent border-2 border-black py-1 px-2 focus:outline-none hover:bg-black hover:text-white rounded text-lg">
                <a href="/tech">Our Integration with DALL-E 2.</a>
            </button>

            <br />
            
            {!playVideo ? (
                <table className="mt-5"><tr>
                    <td valign="middle"><img width="500" alt="Screenshot-3" src="https://user-images.githubusercontent.com/35755386/213597146-7b062310-df93-488d-9dc6-e84036f26eb4.png" /></td>

                    <td valign="middle"><img width="500" alt="Screenshot-4" src="https://user-images.githubusercontent.com/35755386/213597161-cd6da778-b4bc-4d40-ba5e-7a4c33982afd.png" /></td>

                    <td valign="middle"><img width="500" alt="Screenshot-5" src="https://user-images.githubusercontent.com/35755386/213597165-01129c78-c6d7-4886-b257-91c5bb1b5162.png" /></td>

                    <td valign="middle"><img width="500" alt="Screenshot-7" src="https://user-images.githubusercontent.com/35755386/213597182-8f16f327-549c-4aa2-a604-25315db092de.png" /></td>
                </tr></table>
            ) : (
                <div className="mt-5 flex">
                    <YouTube videoId="MVJHn4Xkk3Y" opts={opts} />
                </div>
            )}
        </div>
    );
}