import { Typography } from "@material-tailwind/react";
import { Mount } from "../transitions";
import MetaTag from "./MetaTag";

export default function Tech() {
    return (
        <div className="home">
            <MetaTag
                title="The Technology"
                description="The technology behind the project."
                keywords="technology behind the project"
                imgsrc="https://user-images.githubusercontent.com/35755386/213597146-7b062310-df93-488d-9dc6-e84036f26eb4.png"
                url="https://www.havit.space/tech"
            />
            
            <Typography
                as="big"
                variant="h1"
                className="text-5xl mr-4 cursor-pointer py-1.5 font-normal">
                    <b>Powered by DALL-E 2.</b>
            </Typography>

            <br />
            <br />

            <Typography
                as="big"
                variant="h1"
                className="text-3xl mr-4 cursor-pointer py-1.5 font-normal">
                    <b>What is DALL-E 2?</b>
                    <br />
                    <p>It is a powerful AI image generating tool developed by OpenAI.</p>
                    <br />
                    <b>What is DALL-E 2 used for?</b>
                    <br />
                    <p>It is used to add new artistic figures or elements to the existing image that the user has taken or uploaded to our server, hosted by Google Firebase.</p>
            </Typography>

            <p className="text-sm text-gray-500">Early access build. OpenAI's Image API is currently in beta.</p>

            <Mount show content={
                <table className="mt-5"><tr>
                    <td valign="middle"><img width="500" alt="Screenshot-3" src="Screenshot1.jpg" /></td>

                    <td valign="middle"><img width="500" alt="Screenshot-4" src="Screenshot2.jpg" /></td>

                    <td valign="middle"><img width="500" alt="Screenshot-5" src="Screenshot3.jpg" /></td>
                </tr></table>
            } />
        </div>
    );
}