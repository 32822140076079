import { Helmet } from 'react-helmet';
import { MetaTagProps } from '../types';

/*
* The MetaTag component is used to render the meta tags
* The Helmet component is used to wrap the meta tags
* The title prop is used to render the title tag
* The description prop is used to render the description meta tag
* The keywords prop is used to render the keywords meta tag
* The imgsrc prop is used to render the image meta tag
* The url prop is used to render the canonical link tag
*/

const MetaTag = (props: MetaTagProps) => {
    /*
    REACT HELMET TUTORIAL:
    https://velog.io/@miyoni/noSSRyesSEO
    */

    return (
        <Helmet>
            <title>{props.title}</title>

            <meta name="description" content={props.description} />
            <meta name="keywords" content={props.keywords} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={props.title} />
            <meta property="og:site_name" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:image" content={props.imgsrc} />
            <meta property="og:url" content={props.url} />

            <meta name="twitter:title" content={props.title} />
            <meta name="twitter:description" content={props.description} />
            <meta name="twitter:image" content={props.imgsrc} />

            <link rel="canonical" href={props.url} />
            <link rel="icon" type="image/png" href="%PUBLIC_URL%/favicon.ico" sizes="16x16" />
        </Helmet>
    );
};

export default MetaTag;