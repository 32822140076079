import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Layout from "./pages/Layout";
import NoPage from './pages/NoPage';
import Privacy from './pages/Privacy'
import Tech from "./pages/Tech";
import People from "./pages/People";

/*
* The App component is used to render the routes
* The BrowserRouter component is used to wrap the Routes component
* The Routes component is used to render the routes
* The Route component is used to render the component that is passed in
* The path prop is used to determine which component to render
* The * path is used to render the NoPage component if the path is not found
* The exact prop is used to determine if the path should be an exact match
* The element prop is used to render the component that is passed in
*/

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout Component={Home} />} />
        <Route path="*" element={<Layout Component={NoPage} />} />
        <Route path="/privacy" element={<Layout Component={Privacy} />} />
        <Route path="/tech" element={<Layout Component={Tech} />} />
        <Route path="/people" element={<Layout Component={People} />} />
      </Routes>
    </BrowserRouter>
  );
}
