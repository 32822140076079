import React from 'react'

import Header from './Header';
import Footer from './Footer';

import { LayoutProps } from '../types';
import { Mount } from '../transitions';

/*
* The Layout component is used to render the header, footer, and main content
* The Component prop is used to render the main content
* The Header component is used to render the header
* The Footer component is used to render the footer
*/

const Layout: React.FunctionComponent<LayoutProps> = ({ Component }) => {
    return (
        <>
            <Header />
            <div className="mx-auto max-w-screen-xl py-2 px-4 lg:px-8 lg:py-4">
                <Mount show content={<Component />} />
            </div>
            <Footer />
        </>
    );
}

export default Layout;